<template>
  <DefaultTemplate
    :footer="false"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <el-row :gutter="20" type="flex" justify="center" v-if="!loading">
      <el-col :span="24" :offset="0">
        <el-row>
          <el-col
            :span="24"
            :xs="{ span: 24, offset: 0 }"
            :sm="{ span: 24, offset: 0 }"
            :md="{ span: 18, offset: 3 }"
            :lg="{ span: 18, offset: 3 }"
            :xl="{ span: 12, offset: 6 }"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form
                  label-position="top"
                  :model="form"
                  ref="ruleForm"
                  :rules="rules"
                >
                  <h1 class="text-center color-main font-weight-normal mg-b-2">
                    My Profile
                  </h1>
                  <el-upload
                    action="#"
                    class="text-center"
                    ref="upload"
                    list-type="picture-card"
                    :auto-upload="false"
                    :on-change="uploadImage"
                    :show-file-list="false"
                  >
                    <img
                      v-if="form.profileImage != '' && !imageFile"
                      :src="
                        imageUrl +
                        'profile/' +
                        user._id +
                        '/' +
                        form.profileImage
                      "
                      class="showavatar 1s"
                    />
                    <div v-else-if="showDefault == false">
                      <i class="fas fa-user"></i>
                    </div>

                    <img v-else :src="getUrlImage()" class="showavatar" />
                    <img src="@/assets/icon/add-pic.png" class="add-pic" />
                  </el-upload>
                  <el-form-item label="ชื่อ" prop="name">
                    <el-input
                      v-model="form.name"
                      id="name"
                      @blur="form.name = form.name.trim()"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="นามสกุล" prop="surname">
                    <el-input
                      v-model="form.surname"
                      id="surname"
                      @blur="form.surname = form.surname.trim()"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="ชื่อเล่น" prop="nickname">
                    <el-input
                      v-model="form.nickname"
                      id="nickname"
                      @blur="form.nickname = form.nickname.trim()"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="อีเมล" prop="email">
                    <el-input
                      v-model="form.email"
                      disabled
                      id="email"
                      @blur="form.email = form.email.trim()"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="เบอร์โทรศัพท์"
                    prop="phone"
                    class="fix-label"
                  >
                    <el-input
                      id="mobilephone"
                      v-model="form.phone"
                      @blur="form.phone = form.phone.trim()"
                      maxlength="10"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="ประเภท"
                    prop="companytype"
                    v-if="user.role == 50"
                  >
                    <el-input
                      v-model="companytype"
                      disabled
                      id="companytype"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="บริษัท"
                    prop="company"
                    v-if="user.role == 50"
                  >
                    <el-input
                      v-model="setCompany"
                      disabled
                      id="company"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="มาตรฐาน"
                    prop="standard"
                    v-if="user.role == 50"
                  >
                    <el-input
                      v-model="setStandard"
                      disabled
                      id="standard"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="CAT No."
                    prop="catNo"
                    v-if="user.role == 50"
                  >
                    <el-input v-model="catNo" disabled id="catNo"></el-input>
                  </el-form-item>
                  <div class="flex-between mg-t-4">
                    <el-button round @click="$router.go(-1)">Cancel</el-button>
                    <el-button
                      type="warning"
                      round
                      :loading="loadingBtn"
                      @click="submitProfile()"
                      >Save</el-button
                    >
                  </div>
                </el-form>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP, HTTPTYPELIVE } from "@/service/axios";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      _token: (state) => state._token,
      _id: (state) => state.user._id,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      user: (state) => state.user,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  data() {
    return {
      loading: false,
      loadingBtn: false,
      form: {
        profileImage: "",
        name: "",
        surname: "",
        nickname: "",
        email: "",
        phone: "",
      },
      showDefault: false,
      showProfile: true,
      imageFile: null,
      rules: {
        name: [
          {
            required: true,
            message: "กรุณากรอกชื่อ",
            trigger: ["blur", "change"],
          },
        ],
        surname: [
          {
            required: true,
            message: "กรุณากรอกนามสกุล",
            trigger: ["blur", "change"],
          },
        ],
        nickname: [
          {
            required: true,
            message: "กรุณากรอกชื่อเล่น",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: ["blur", "change"],
          },
          {
            type: "email",
            message: "กรุณากรอกอีเมลให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "กรุณากรอกเบอร์โทรศัพท์",
            trigger: ["blur", "change"],
          },
          {
            min: 10,
            message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
      },
      companytype: "",
      catNo: "",
      setCompany: "",
      setStandard: "",

      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`user/profile`)
        .then((res) => {
          if (res.data.success) {
            this.form = res.data.obj;
            if (this.user.role == 50) {
              let companyType = res.data.obj.companyType;
              if (companyType.length > 0) {
                let findIso = companyType.find((company) => company.type == 2);
                let findFood = companyType.find((company) => company.type == 1);

                if (findFood != undefined) {
                  this.companytype += "Food safety";
                }

                this.companytype += findFood && findIso ? ", " : "";

                if (findIso != undefined) {
                  this.companytype += "ISO";
                }

                companyType.forEach((row, index) => {
                  if (companyType.length == 2 && index == 1) {
                    this.companytype += ", ";
                    this.catNo += ", ";
                  }
                  let comCustom = "";
                  if (row.type == 1) {
                    comCustom =
                      companyType.length == 1 && row.type == 1 ? "-" : "";

                    this.setStandard += this.mapDataOption(
                      row.standard,
                      this.optionsStandardFood,
                      "standard"
                    );
                  }
                  if (row.type == 2) {
                    comCustom = this.mapDataOption(
                      row.company,
                      this.optionsCompanyIso,
                      "company"
                    );
                    this.setStandard += this.mapDataOption(
                      row.standard,
                      row.company == 1
                        ? this.optionsStandardIsoOne
                        : this.optionsStandardIsoTwo,
                      "standard"
                    );
                  }
                  this.setCompany += comCustom;

                  this.catNo += row.catNo.join(", ");

                  this.setCompany +=
                    index != companyType.length - 1 && row.type == 2
                      ? ", "
                      : "";
                  this.catNo += index != companyType.length - 1 ? ", " : "";
                  this.setStandard +=
                    index != companyType.length - 1 ? ", " : "";
                });
              }
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getUrlImage() {
      if (this.imageFile != null) {
        return URL.createObjectURL(this.imageFile);
      } else {
        return "";
      }
    },
    uploadImage(file) {
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (type[indexOfFile] != "json") {
        if (
          type[indexOfFile] == "jpeg" ||
          type[indexOfFile] == "png" ||
          type[indexOfFile] == "jpg"
        ) {
          this.showProfile = true;
          this.showDefault = true;
          this.imageFile = file.raw;
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
      }
    },
    submitProfile() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loadingBtn = true;

          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "X-CSRF-TOKEN": this.setCsrf,
            },
          };

          var formData = new FormData();

          formData.append("_csrf", this.setCsrf);
          formData.append("name", this.form.name);
          formData.append("surname", this.form.surname);
          formData.append("email", this.form.email);
          formData.append("nickname", this.form.nickname);
          formData.append("phone", this.form.phone);
          if (this.imageFile != null) {
            formData.append("profileImage", this.imageFile);
          }

          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          HTTP.post(`user/profile`, formData, config)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  title: "แก้ไขโปรไฟล์สำเร็จแล้ว!",
                  type: "success",
                  customClass: "success",
                });
                this.$store.commit("SET_USER", res.data.obj);
              }
            })
            .catch((e) => {
              if (typeof e.response != "undefined") {
                if (e.response.data.status == 401) {
                  this.checkAuth();
                } else {
                  this.alertCatchError(e);
                }
              }
            })
            .finally(() => {
              this.loadingBtn = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
